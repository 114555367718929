var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "w-full", attrs: { id: `${_vm.mode}-${_vm.entity}` } },
    [
      _c("section-header", {
        attrs: { "is-loading": _vm.isLoading },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("breadcrumb", {
                  staticClass: "flex flex-1",
                  class: { "text-gray-700": _vm.userId },
                }),
                _vm.userId
                  ? _c("span", { staticClass: "text-gray-700" }, [
                      _vm._v(": "),
                      _vm.userItem
                        ? _c("span", { staticClass: "text-orange-500" }, [
                            _vm._v(" " + _vm._s(_vm.userItem.name) + " "),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "hidden sm:block" },
                  [
                    _vm.mode === "edit"
                      ? _c(
                          "sun-button",
                          {
                            staticClass: "custom-p-1",
                            attrs: {
                              id: `${_vm.mode}-user-remove`,
                              variant: "pill",
                              color: "red",
                            },
                            on: { click: _vm.onDeleteUser },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "flex text-xs" },
                              [
                                _c("rubbish-bin-svg", {
                                  staticClass: "w-5 mr-1",
                                }),
                                _c("span", { staticClass: "leading-5" }, [
                                  _vm._v("Remove"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.connectionFailedToServer ? _c("asterix-error-alert") : _vm._e(),
      _c(
        "card",
        [
          _c(
            "sun-form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm,
                  expression: "showForm",
                },
              ],
              on: {
                submit: _vm.submitForm,
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.submitForm.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "basic-info",
                {
                  attrs: {
                    mode: _vm.mode,
                    entity: _vm.entity,
                    item: _vm.userItem,
                    "email-editable": _vm.isNewForm,
                  },
                },
                [
                  _vm.isNewForm
                    ? _c("asterix-new-password-input", {
                        attrs: { required: "required", "validate-on-blur": "" },
                        model: {
                          value: _vm.password,
                          callback: function ($$v) {
                            _vm.password = $$v
                          },
                          expression: "password",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "card-form",
                { staticClass: "mt-12" },
                [
                  _c("template", { slot: "title" }, [_vm._v(" Roles")]),
                  _c(
                    "template",
                    { slot: "form" },
                    [
                      _c(
                        "form-row",
                        [
                          _c(
                            "sun-label-group",
                            {
                              staticClass: "w-full lg:mr-4",
                              attrs: { text: "Role" },
                            },
                            [
                              _vm.activeContext.id
                                ? _c(
                                    _vm.getComponentByContext[
                                      _vm.activeContext.id
                                    ],
                                    {
                                      tag: "component",
                                      attrs: {
                                        value: _vm.contextRole,
                                        user: _vm.userItem,
                                        simple: "",
                                        roles: _vm.roles,
                                      },
                                      on: { change: _vm.onContextRoleChanged },
                                    }
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._t("extra-cards"),
              _c(
                "div",
                { staticClass: "flex mt-12" },
                [
                  _c("save-button", {
                    attrs: {
                      id: `${_vm.mode}-${_vm.entity}-submit`,
                      loading: _vm.isLoading,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          !_vm.showForm
            ? _c(
                "div",
                [
                  _c("card-form-loading"),
                  _c("card-form-loading", {
                    staticClass: "mt-10",
                    attrs: { rows: 2 },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("delete-modal", {
            attrs: { open: _vm.showDeleteModal },
            on: {
              confirm: _vm.onDeleteUserConfirm,
              cancel: function ($event) {
                _vm.showDeleteModal = false
              },
            },
            scopedSlots: _vm._u([
              {
                key: "description",
                fn: function () {
                  return [_vm._v(_vm._s(_vm.deleteDescription))]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }