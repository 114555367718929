import { mapActions, mapGetters } from 'vuex';
import { IS_LOADING, SET_LOAD } from '../../store/modules/load/keys';

export const loadMixin = {
  computed: {
    ...mapGetters({
      isLoading: IS_LOADING,
    }),
  },
  methods: {
    ...mapActions([SET_LOAD]),
  },
};
