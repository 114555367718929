import { LOADING_TOAST_STATUS } from '@/model/shared/LoadingToastStatus';

export class LoadingToast {
  constructor(
    nameEntity = 'entity',
    entityObject = {},
    canEdit = true,
    showReload = true,
    status = LOADING_TOAST_STATUS.LOADING
  ) {
    this.textSuccess = `The ${nameEntity} ${entityObject?.name || ''} was created`;
    this.textLoading = `The ${nameEntity} ${entityObject?.name || ''} is being created`;
    this.textError = `We found a issue loading ${nameEntity}`;
    this.nameEntity = nameEntity;
    this.entityObject = entityObject;
    this.showReload = showReload;
    this.canEdit = canEdit;
    this.status = status;
  }
}
