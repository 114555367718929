var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sun-alert", { attrs: { dismissible: false, variant: "error" } }, [
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v(" " + _vm._s(_vm.textTitle) + " "),
        ]),
        _c("span", { attrs: { slot: "description" }, slot: "description" }, [
          _vm._v(" " + _vm._s(_vm.textDescription) + " "),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }