<template>
  <div>
    <sun-alert :dismissible="false" variant="error">
      <span slot="title">
        {{ textTitle }}
      </span>
      <span slot="description">
        {{ textDescription }}
      </span>
    </sun-alert>
  </div>
</template>

<script>
import { ERRORS } from '../../../i18n/forms/errors';

export default {
  name: 'AsterixErrorAlert',
  props: {
    textTitle: {
      type: String,
      default: () => ERRORS.en.errorHasOccurred,
    },
    textDescription: {
      type: String,
      default: () => ERRORS.en.connectToAPI,
    },
  },
};
</script>

<style scoped></style>
