var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        attrs: { status: _vm.sectionStatus },
        scopedSlots: _vm._u([
          {
            key: "header-right",
            fn: function () {
              return [
                _c(
                  "router-link",
                  { attrs: { to: _vm.newUserRoute } },
                  [
                    _c(
                      "sun-button",
                      {
                        staticClass:
                          "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800",
                        attrs: {
                          variant: "pill",
                          color: "gray",
                          "data-testid": "new-user-button",
                        },
                      },
                      [_vm._v(" + New user ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("sun-filter-layout", {
                  attrs: {
                    id: "filter-box",
                    "filters-added": _vm.filterFind,
                    "filters-available": _vm.filters,
                    "has-error": _vm.anyError,
                    "show-reset": "",
                  },
                  on: {
                    close: _vm.filtersResetErrors,
                    change: _vm.filtersSetFiltersFind,
                    remove: _vm.filtersResetErrors,
                    reset: _vm.resetFilters,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "quickFilters",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "flex min-w-max" },
                              [
                                _c("sun-search-input", {
                                  attrs: {
                                    "class-input": "text-sm",
                                    value: _vm.filterQuick["name"],
                                  },
                                  on: { search: _vm.onQuickFiltersSearch },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: `filter.name`,
                        fn: function ({ filter, onSelect, isDuplicate }) {
                          return [
                            _c("sun-input", {
                              attrs: {
                                "data-testid": `filter-input-${filter.name}`,
                                type: "text",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                                enter: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.email`,
                        fn: function ({ filter, onSelect, isDuplicate }) {
                          return [
                            _c("sun-input", {
                              attrs: {
                                "data-testid": `filter-input-${filter.name}`,
                                type: "text",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                                enter: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.contextRole.role`,
                        fn: function ({
                          filter,
                          onSelect,
                          value,
                          isDuplicate,
                        }) {
                          return [
                            _c("sun-select", {
                              staticClass: "w-24 cursor-pointer",
                              attrs: {
                                options: filter.options,
                                "close-on-select": "",
                                "disable-selected-options": "",
                                "track-by": "value",
                                label: "name",
                                value: _vm.filtersMakeFiltersForSelect(value),
                                "add-hex-color": "orange",
                                "class-input":
                                  "pt-0 shadow-none rounded-none multiselect",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.userPublisherLink.publisherId`,
                        fn: function ({
                          filter,
                          onSelect,
                          value,
                          isDuplicate,
                        }) {
                          return [
                            _c("asterix-async-select", {
                              staticClass: "w-24",
                              attrs: {
                                name: "publisher-select-filter",
                                service: filter.service,
                                "track-by": filter.trackBy,
                                label: "name",
                                value: _vm.filtersMakeFiltersForSelect(value),
                                "is-array-object": "",
                                "disable-selected-options": "",
                                "close-on-select": "",
                                "class-input": "pt-0 shadow-none rounded-none",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.items,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-data-table", {
                  staticClass: "mt-2",
                  attrs: {
                    hoverable: "",
                    headers: _vm.headers,
                    content: _vm.items,
                    loading: _vm.isLoading,
                    striped: _vm.striped,
                  },
                  on: { sort: _vm.onSortTable },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `col.name`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              {
                                class: columnClass,
                                attrs: { title: item.name },
                              },
                              [
                                _c("asterix-column-router-link", {
                                  attrs: {
                                    item: item,
                                    route: _vm.userEditRoute,
                                    "param-value": "id",
                                    "param-name": "id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.actions`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("table-action-menu", {
                                  attrs: {
                                    actions: _vm.actions,
                                    item: item,
                                    items: _vm.items,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onActionClick($event, item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("asterix-no-data", { staticClass: "bg-white" }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-pagination-page", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                  key: _vm.currentPage,
                  staticClass: "my-6",
                  attrs: {
                    "total-pages": _vm.totalPages,
                    "total-items": _vm.totalItems,
                    "current-page": _vm.currentPage,
                    value: _vm.itemsPerPage,
                  },
                  on: {
                    changePage: function ($event) {
                      return _vm.goToPage({ page: $event })
                    },
                    changePerPage: _vm.changeItemsPerPage,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("delete-modal", {
        attrs: { open: _vm.showModal },
        on: { cancel: _vm.closeDeleteModal, confirm: _vm.deleteElement },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v(_vm._s(_vm.deleteUserText))]
            },
            proxy: true,
          },
          {
            key: "description",
            fn: function () {
              return [_vm._v(_vm._s(_vm.deleteUserConfirmText))]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }