import Filter from '@/model/shared/Filter';
import Column from '@/model/shared/Column';

const CONFIG = {
  filters: [new Filter('USER_NAME', 'name', 'name'), new Filter('USER_EMAIL', 'email', 'email')],
  headers: [
    new Column('NAME', 'name').asSort().setClass('font-bold truncate'),
    new Column('EMAIL', 'email').asSort().setClass('truncate'),
    new Column('ACTIONS', 'actions').setClass('w-4'),
  ],
  actions: [{ name: 'Edit' }, { name: 'Delete' }],
};

export default CONFIG;
