<template>
  <section :id="`${mode}-${entity}`" class="w-full">
    <section-header :is-loading="isLoading">
      <template #title>
        <breadcrumb class="flex flex-1" :class="{ 'text-gray-700': userId }" />
        <span v-if="userId" class="text-gray-700"
          >:
          <span v-if="userItem" class="text-orange-500">
            {{ userItem.name }}
          </span>
        </span>
      </template>
      <template #right>
        <div class="hidden sm:block">
          <sun-button
            v-if="mode === 'edit'"
            :id="`${mode}-user-remove`"
            variant="pill"
            color="red"
            class="custom-p-1"
            @click="onDeleteUser"
          >
            <span class="flex text-xs">
              <rubbish-bin-svg class="w-5 mr-1" />
              <span class="leading-5">Remove</span>
            </span>
          </sun-button>
        </div>
      </template>
    </section-header>
    <asterix-error-alert v-if="connectionFailedToServer" />

    <card>
      <sun-form v-show="showForm" @submit="submitForm" @keypress.enter="submitForm">
        <basic-info :mode="mode" :entity="entity" :item="userItem" :email-editable="isNewForm">
          <asterix-new-password-input v-if="isNewForm" v-model="password" required="required" validate-on-blur />
        </basic-info>
        <card-form class="mt-12">
          <template slot="title"> Roles</template>
          <template slot="form">
            <form-row>
              <sun-label-group text="Role" class="w-full lg:mr-4">
                <component
                  :is="getComponentByContext[activeContext.id]"
                  v-if="activeContext.id"
                  :value="contextRole"
                  :user="userItem"
                  simple
                  :roles="roles"
                  @change="onContextRoleChanged"
                />
              </sun-label-group>
            </form-row>
          </template>
        </card-form>

        <slot name="extra-cards"></slot>

        <div class="flex mt-12">
          <save-button :id="`${mode}-${entity}-submit`" :loading="isLoading" />
        </div>
      </sun-form>
      <div v-if="!showForm">
        <card-form-loading></card-form-loading>
        <card-form-loading :rows="2" class="mt-10"></card-form-loading>
      </div>

      <delete-modal :open="showDeleteModal" @confirm="onDeleteUserConfirm" @cancel="showDeleteModal = false">
        <template #description>{{ deleteDescription }}</template>
      </delete-modal>
    </card>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { createUser, getUserById, updateUser } from '@/services/modules/Core/user';
import { getUserById as getSocialAudienceUserById } from '@/services/modules/socialAudience/user';
import metaInfo from '@/mixins/common/metaInfo';
import SaveButton from '@/components/atoms/SaveButton';
import SectionHeader from '@/components/atoms/SectionHeader';
import CardForm from '@/components/atoms/CardForm';
import BasicInfo from '@/components/organisms/shared/formFields/BasicInfo';
import { AVAILABLE_CONTEXTS, CONTEXTS } from '@/model/shared/contexts';
import { SET_LOAD, IS_LOADING } from '@/store/modules/load/keys';
import { ERRORS } from '@/i18n/forms/errors';
import { loadMixin } from '@/mixins/common/load';
import { ROLES } from '@/model/shared/roles';
import { CONTEXT_NS, ACTIVE_CONTEXT_KEY, ACTIVE_CLIENT_KEY } from '@/store/modules/context/keys';
import { LoadingToast } from '@/entities/shared/LoadingToast';
import { SET_LOADING_TOAST, SET_STATE_LOADING_TOAST } from '@/store/modules/loadingToast/keys';
import { USER } from '@/store/modules/auth/keys';
import AsterixErrorAlert from '@/components/molecules/shared/AsterixErrorAlert';
import Card from '@/components/atoms/Card/Card';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import FormRow from '@/components/atoms/FormRow/FormRow';
import CONFIG from './config';
import RubbishBinSvg from '@/components/icons/RubbishBinSvg';
import Breadcrumb from '@/components/atoms/Breadcrumb';
import UserCore from '@/entities/Auth/UserCore';
import ContextRolesSelector from '@/components/organisms/shared/contextRoles/ContextRolesSelector';
import EcommerceContextRolesSelector from '@/components/organisms/shared/contextRoles/EcommerceContextRolesSelector';
import ThirdPartyContextRolesSelector from '@/components/organisms/shared/contextRoles/ThirdPartyContextRolesSelector';
import DashboardSSPContextRolesSelector from '@/components/organisms/shared/contextRoles/DashboardSSPContextRolesSelector';
import SocialAudienceContextRolesSelector from '@/components/organisms/shared/contextRoles/SocialAudienceContextRolesSelector';
import CardFormLoading from '@/components/atoms/CardFormLoading.vue';
import apiRequest from '@/utils/apiRequest';
import DeleteModal from '@/components/organisms/shared/DeleteModal';
import AsterixNewPasswordInput from '@/components/atoms/AsterixNewPasswordInput';
import Finance from '@/entities/socialAudience/Finance';
import SocialAudienceUser from '@/entities/socialAudience/SocialAudienceUser';

/** @type {import('@/entities/Auth/UserCore').ContextRole} */
const contextRolesDefault = { context: CONTEXTS.CORE.id, role: ROLES.USER.id, settings: {} };

export default {
  name: 'UsersEdit',
  components: {
    AsterixErrorAlert,
    AsterixNewPasswordInput,
    BasicInfo,
    Breadcrumb,
    Card,
    CardForm,
    CardFormLoading,
    FormRow,
    RubbishBinSvg,
    SaveButton,
    SectionHeader,
    ContextRolesSelector,
    EcommerceContextRolesSelector,
    ThirdPartyContextRolesSelector,
    DashboardSSPContextRolesSelector,
    SocialAudienceContextRolesSelector,
    DeleteModal,
  },
  mixins: [activeClientMixin, loadMixin, metaInfo],
  data: () => ({
    formErrors: CONFIG.errors,
    i18nErrors: ERRORS.en,
    userItem: new UserCore(null, '', '', '', [contextRolesDefault]),
    entity: 'user',
    passwordMinlength: 5,
    connectionFailedToServer: false,
    passwordError: null,
    showDeleteModal: false,
    contextRole: { settings: {} },
    password: null,
  }),
  computed: {
    ...mapGetters({
      user: USER,
      isLoading: IS_LOADING,
    }),
    ...mapState(CONTEXT_NS, {
      activeClient: ACTIVE_CLIENT_KEY,
      activeContext: ACTIVE_CONTEXT_KEY,
    }),
    userId() {
      return this.$route.params.id;
    },
    mode() {
      return this.userId ? 'edit' : 'new';
    },
    isNewForm() {
      return this.mode === 'new';
    },
    showForm() {
      return this.isNewForm || (this.mode === 'edit' && !!this.userItem.id);
    },
    roleHierarchy() {
      return CONTEXTS[this.activeContext.id].roleHierarchy;
    },
    userActiveRol() {
      return this.user.contextRoles.find(({ context }) => context === this.activeContext.id).role;
    },
    roles() {
      const roleHierarchy = this.roleHierarchy[this.userActiveRol];
      const roles = AVAILABLE_CONTEXTS[this.activeContext.id].roles;
      return roles.filter(role => roleHierarchy.includes(role));
    },
    deleteDescription() {
      return `You are going to remove the user ${this.userItem.name}. This action can not be undone.`;
    },
    routeToGo() {
      const targetContextUrl = this.activeContext.baseUrl;
      return `${targetContextUrl}-users`;
    },
    getComponentByContext() {
      return {
        [CONTEXTS.ECOMMERCE.id]: 'EcommerceContextRolesSelector',
        [CONTEXTS.THIRD_PARTY.id]: 'ThirdPartyContextRolesSelector',
        [CONTEXTS.DASHBOARD_SSP.id]: 'DashboardSSPContextRolesSelector',
        [CONTEXTS.SOCIAL_AUDIENCE.id]: 'SocialAudienceContextRolesSelector',
      };
    },
  },
  async beforeMount() {
    if (!this.isNewForm) {
      await this.setUserData();
    }
    this.userItem.clients = [this.activeClient];
    this.onContextRoleChanged();
  },
  methods: {
    ...mapActions({
      setLoad: SET_LOAD,
      createToast: CREATE_TOAST,
      setLoadingPropToast: SET_STATE_LOADING_TOAST,
      setPropsLoadingToast: SET_LOADING_TOAST,
    }),
    onDeleteUser() {
      this.showDeleteModal = true;
    },
    async onDeleteUserConfirm() {
      try {
        this.userItem.deleteClientByContext(this.activeContext.id, this.activeClient.id);
        await updateUser(this.userItem);
        this.showDeleteModal = false;
        await this.$router.push({ name: `${this.activeContext.baseUrl}-users` });
      } catch (error) {
        this.createToast(Toast.error('User not deleted', error.message));
      }
    },
    onActiveClientChange() {
      const targetContextUrl = this.activeContext.baseUrl;
      this.$router.replace({ name: `${targetContextUrl}-users` });
    },
    metaTitleReplacement() {
      return { user: this.userItem.name } || {};
    },
    async setUserData() {
      this[SET_LOAD]();
      await apiRequest(async () => {
        let data = {};
        if (this.contextRole.context === CONTEXTS.SOCIAL_AUDIENCE.id) {
          data = getSocialAudienceUserById(this.userId);
        } else {
          data = await getUserById(this.userId);
        }
        this.userItem = data.data;
        this.contextRole = this.userItem.contextRoles.find(cr => cr.context === this.activeContext.id);
        this.$emit('user-settings', this.contextRole.settings);
        this.updateTitle();
      }).catch(async error => {
        this.createToast(Toast.error(`Can't find user`, error.message));
        await this.$router.push({ name: `${this.activeContext.baseUrl}-users` });
      });
      this[SET_LOAD](false);
    },
    setUserObject(form) {
      const nameValue = form.name.value;
      const emailValue = form.email.value;
      const passwordValue = this.password || '';

      const contextRoles = this.userItem.contextRoles;
      const contextRoleIndex = contextRoles.findIndex(cr => cr.context === this.activeContext.id);
      if (contextRoleIndex === -1) {
        contextRoles.push(this.contextRole);
      } else {
        contextRoles[contextRoleIndex] = this.contextRole;
      }
      if (this.contextRole.context === CONTEXTS.SOCIAL_AUDIENCE.id && this.contextRole.role === ROLES.CLIENT.id) {
        const finance = new Finance(form.walletType.value, form.fee.value, form.techCost.value);
        return new SocialAudienceUser(
          this.userId,
          nameValue,
          emailValue,
          passwordValue,
          contextRoles,
          true,
          [],
          [],
          [],
          finance
        );
      }
      return new UserCore(this.userId, nameValue, emailValue, passwordValue, contextRoles, true);
    },
    async submitForm({ valid, form }) {
      if (!valid) return;
      await this.save(form);
    },
    async save(form) {
      const createdOrUpdated = this.isNewForm ? 'created' : 'updated';
      try {
        this.loading = true;
        this.setLoad();
        const userFormObject = this.setUserObject(form);

        if (this.isNewForm) {
          await createUser(userFormObject);
        } else {
          await updateUser(userFormObject);
          this.$router.push({
            name: this.routeToGo,
            params: { id: this.userId, entity: 'user' },
            query: { ...this.$route.query, entity: 'user' },
          });
        }

        this.createToast(
          Toast.success(`User ${createdOrUpdated}`, `User ${userFormObject.name} was ${createdOrUpdated} successfully.`)
        );

        if (this.isNewForm) {
          const userListLocation = { name: `${this.activeContext.baseUrl}-users` };
          await this.$router.push(userListLocation);
        }
      } catch ({ message }) {
        this.createToast(Toast.error(`User not ${createdOrUpdated}`, message));
        this.showErrors(message);
      } finally {
        this.loading = false;
        this.setLoad(false);
      }
    },
    showErrors(message) {
      this.formErrors.email = message.includes('email') ? message : '';
      this.formErrors.name = message.includes('name') ? message : '';
    },
    setLoadingToast(user) {
      try {
        const loadingToast = new LoadingToast('user', user, true);
        this.setPropsLoadingToast(loadingToast);
        this.$router.push({ name: this.routeToGo });
        this.startLoadingToast(user);
      } catch (e) {
        this.hasError = true;
      }
    },
    startLoadingToast(user, times = 1) {
      setTimeout(async () => {
        // If found appear the success and end recursive loop
        try {
          await getUserById(user.id);
          this.setLoadingPropToast(false);
        } catch (error) {
          // Call recursive the method
          this.startLoadingToast(user, ++times);
        }
      }, 500 * times);
    },
    onContextRoleChanged() {
      this.contextRole.context = this.activeContext.id;
      this.contextRole.settings.clientId = this.activeClient.id;
      this.$emit('context-role-changed', this.contextRole);
    },
  },
};
</script>
<style scoped>
::v-deep .multiselect__option div .pr-10 {
  padding-right: 12px;
  max-width: none;
}

@media screen and (max-width: 1400px) {
  ::v-deep .multiselect__option div .pr-10 {
    padding-right: 12px;
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 1220px) {
  ::v-deep .multiselect__option div .pr-10 {
    padding-right: 12px;
    max-width: none;
  }
}
</style>
