<template>
  <div>
    <asterix-section :status="sectionStatus">
      <template #header-right>
        <router-link :to="newUserRoute">
          <sun-button
            variant="pill"
            class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800"
            color="gray"
            data-testid="new-user-button"
          >
            + New user
          </sun-button>
        </router-link>
      </template>

      <template #content>
        <sun-filter-layout
          id="filter-box"
          :filters-added="filterFind"
          :filters-available="filters"
          :has-error="anyError"
          show-reset
          @close="filtersResetErrors"
          @change="filtersSetFiltersFind"
          @remove="filtersResetErrors"
          @reset="resetFilters"
        >
          <template #quickFilters>
            <div class="flex min-w-max">
              <sun-search-input class-input="text-sm" :value="filterQuick['name']" @search="onQuickFiltersSearch" />
            </div>
          </template>
          <!--Filters Slot :Start -->
          <template #[`filter.name`]="{ filter, onSelect, isDuplicate }">
            <sun-input
              :data-testid="`filter-input-${filter.name}`"
              type="text"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
              @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.email`]="{ filter, onSelect, isDuplicate }">
            <sun-input
              :data-testid="`filter-input-${filter.name}`"
              type="text"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
              @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.contextRole.role`]="{ filter, onSelect, value, isDuplicate }">
            <sun-select
              :options="filter.options"
              close-on-select
              disable-selected-options
              track-by="value"
              label="name"
              :value="filtersMakeFiltersForSelect(value)"
              class="w-24 cursor-pointer"
              add-hex-color="orange"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.userPublisherLink.publisherId`]="{ filter, onSelect, value, isDuplicate }">
            <asterix-async-select
              name="publisher-select-filter"
              :service="filter.service"
              :track-by="filter.trackBy"
              label="name"
              :value="filtersMakeFiltersForSelect(value)"
              class="w-24"
              is-array-object
              disable-selected-options
              close-on-select
              class-input="pt-0 shadow-none rounded-none"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.items, onSelect)"
            />
          </template>
        </sun-filter-layout>

        <sun-data-table
          hoverable
          :headers="headers"
          :content="items"
          :loading="isLoading"
          :striped="striped"
          class="mt-2"
          @sort="onSortTable"
        >
          <template #[`col.name`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass" :title="item.name">
              <asterix-column-router-link :item="item" :route="userEditRoute" param-value="id" param-name="id" />
            </sun-data-table-cell>
          </template>
          <template #[`col.actions`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
            </sun-data-table-cell>
          </template>

          <template #empty>
            <asterix-no-data class="bg-white" />
          </template>
        </sun-data-table>

        <sun-pagination-page
          v-show="!isLoading"
          :key="currentPage"
          :total-pages="totalPages"
          :total-items="totalItems"
          :current-page="currentPage"
          :value="itemsPerPage"
          class="my-6"
          @changePage="goToPage({ page: $event })"
          @changePerPage="changeItemsPerPage"
        />
      </template>
    </asterix-section>

    <delete-modal :open="showModal" @cancel="closeDeleteModal" @confirm="deleteElement">
      <template #title>{{ deleteUserText }}</template>
      <template #description>{{ deleteUserConfirmText }}</template>
    </delete-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { indexMixin } from '@/mixins/index/indexMixin';
import filtersMixin from '@/mixins/filters/filtersMixin';
import { USER_RESOURCE } from '@/services/keys';
import { COMMON_TEXTS } from '@/i18n/commonTexts';
import { CoreApi } from '@/services/api/CoreApi';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import AsterixSection from '@/components/templates/AsterixSection';
import { getUsers, updateUser } from '@/services/modules/Core/user';
import { CONTEXT_NS, ACTIVE_CONTEXT_KEY, ACTIVE_CLIENT_KEY } from '@/store/modules/context/keys';
import DeleteModal from '@/components/organisms/shared/DeleteModal';
import CONFIG from './config';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import AsterixColumnRouterLink from '@/components/atoms/AsterixColumnRouterLink';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';

export default {
  name: 'UsersList',
  components: {
    AsterixSection,
    DeleteModal,
    AsterixColumnRouterLink,
    AsterixAsyncSelect,
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: { name: undefined },
    }),
    indexMixin,
  ],
  props: {
    extraFilters: {
      type: Array,
      default: () => [],
    },
    striped: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showModal: false,
    items: [],
    headers: CONFIG.headers,
    totalPages: 0,
    resource: USER_RESOURCE,
    currentApi: new CoreApi(),
    deleteUserText: COMMON_TEXTS.en.names.user.actions.delete,
    deleteUserConfirmText: COMMON_TEXTS.en.names.user.messages.deleteConfirm,
    actions: CONFIG.actions,
    isLoading: true,
    /** @type {import('@/entities/Auth/UserCore').default | null} */
    selectedToDelete: null,
  }),
  computed: {
    ...mapState(CONTEXT_NS, {
      activeClient: ACTIVE_CLIENT_KEY,
      activeContext: ACTIVE_CONTEXT_KEY,
    }),
    newUserRoute() {
      const targetContextUrl = this.activeContext?.baseUrl;
      return this.activeContext ? { name: `${targetContextUrl}-new-user` } : {};
    },
    userEditRoute() {
      const targetContextUrl = this.activeContext.baseUrl;
      return { name: `${targetContextUrl}-edit-user` };
    },
  },
  created() {
    this.filterDefault = [{ name: 'client.id', value: this.activeClient?.id }];
    if (this.extraFilters.length) {
      this.filters = this.filters.concat(this.extraFilters);
    }
  },
  async mounted() {
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();

    await this.getTableItems();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    onActionClick(event, item) {
      switch (event.name) {
        case 'Edit':
          this.routeToEditUser(item.id);
          break;
        case 'Delete':
          this.deleteUser(item);
          break;
      }
    },
    routeToEditUser(userId) {
      const targetContextUrl = this.activeContext.baseUrl;
      this.$router.push({ name: `${targetContextUrl}-edit-user`, params: { id: userId } });
    },
    deleteUser(user) {
      this.showModal = true;
      this.selectedToDelete = user;
    },
    closeDeleteModal() {
      this.showModal = false;
    },
    async deleteElement() {
      try {
        this.selectedToDelete.deleteClientByContext(this.activeContext.id, this.activeClient.id);

        await updateUser(this.selectedToDelete);
        this.addQueryParams({ page: 1 });
        this.getTableItems();

        this.createToast(Toast.success('Delete done', `Delete of ${this.selectedToDelete.name} was successfully`));
      } catch (error) {
        this.createToast(Toast.error('Delete was not possible', error.message));
      } finally {
        this.showModal = false;
      }
    },
    async getTableItems() {
      this.isLoading = true;
      const { data, isCancel } = await this.getItemsFromAPI(getUsers);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },
  },
};
</script>
